"use client";

import { Fragment, useEffect } from "react";

import { useRouter, useSearchParams } from "next/navigation";

import { Brands } from "shared-lib/Brands";
import { getHasSubscribedCookie } from "shared-lib/helpers/storageFunctions";
import { brand } from "shared-lib/helpers/envFunctions";

import { GlobalActionTypes, useGlobalState } from "@/context/globalState";

type Props = {
  type: string;
  slug: string;
  imgSrc: string;
};

export default function LandingRedirector({ type, slug, imgSrc }: Props) {
  const router = useRouter();
  const params = useSearchParams();
  const [globalState, dispatch] = useGlobalState();

  const isPaidOrSocial =
    params.has("utm_medium", "display") || params.has("utm_medium", "social");
  const userHasSubscribed = getHasSubscribedCookie();
  const reasonsToRedirect =
    brand.id === Brands.bigrecipe &&
    isPaidOrSocial &&
    !globalState.seenLandingPage &&
    !userHasSubscribed;

  useEffect(() => {
    if (globalState.seenLandingPage) {
      return;
    }

    if (reasonsToRedirect) {
      dispatch({
        type: GlobalActionTypes.SEEN_LANDING_PAGE,
        payload: true,
      });

      const extraParamsValues = {
        image: `imgsrc${imgSrc.length !== 0 ? `=${encodeURI(imgSrc)}` : ""}`,
        type: `type${type.length !== 0 ? `=${type}` : ""}`,
        slug: `slug${slug.length !== 0 ? `=${slug}` : ""}`,
      };
      const urlBase = `/1p?${params.toString()}`;
      const extraParams = Object.values(extraParamsValues);
      const url = urlBase + "&" + extraParams.join("&");
      router.push(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Fragment key="landing-page-redirector" />;
}
